import { connect } from "react-redux";
import Counter from "./Counter";


// Map redux State to component props
function mapStateToProps(state){
    return {
        countValue: state.count,
    };
}
// Action
var increaseAction = {type:"increase"};
var decreaseAction ={type :"decrease"};

function mapDispatchToProps(dispatch){
    return{
        increaseCount:function(){
            return dispatch(increaseAction);
        },
        decreaseCount:function(){
            return dispatch(decreaseAction);
        }
    };
}

var connectedComponent=connect(mapStateToProps,mapDispatchToProps)(Counter);

export default connectedComponent;